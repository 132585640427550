import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { Devtools } from '@ui-devtools/tailwind'
import Whatwedo from '../components/WhatWeDo'


export default function Home() {
  return (
    <div className="min-h-screen bg-grey-100">
      <Devtools>
        <Layout>
          <main>
            <section className="h-full">
              <div className="pb-8 bg-cover bg-hero-background bg-blend-darken sm:pb-12 lg:pb-12">
                <div className="overflow-hidden sm:pt-12 lg:relative lg:py-48">
                  <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
                    <div className="pb-8">
                      <div className="pb-4">

                        <a href="#" className="inline-flex space-x-4">
                          <span className="tracking-widest px-2.5 py-1 text-white font-medium text-xs uppercase">
                            Virgin Transformers
                  </span>
                        </a>
                      </div>
                      <div>

                        <div className="sm:max-w-xl">
                          <h1 className="font-medium tracking-wide text-white capitalize transition-none sm:text-5xl md:text-7xl animate-none">
                            More power to you
                </h1>
                          <p className="mt-6 mb-6 text-xl text-white">
                            Virgin Transformers is a world class manufacturer, repairer and distributor of quality electrical equipment to the South African market.
                </p>
                        </div>
                        <div className="block mt-12">
                          <Link to="/about">
                            <button className="px-5 py-3 text-base font-medium text-white uppercase bg-red-700 border border-transparent rounded-md shadow hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:px-10"
                            >
                              About Us
                  </button></Link>
                        </div>
                        <div className="mt-6">
                          <div className="inline-flex items-center divide-x divide-grey-300">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
                    <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                      <div className="hidden sm:block">
                        <div className="absolute inset-y-0 hidden w-screen left-1/2 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full bg-gray-50 bg-opacity-40" />
                        <svg
                          className="absolute -mr-3 top-8 right-1/2 lg:m-0 lg:left-0"
                          width={404}
                          height={392}
                          fill="none"
                          viewBox="0 0 404 392"
                        >
                          <defs>
                            <pattern
                              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                              x={0}
                              y={0}
                              width={20}
                              height={20}
                              patternUnits="userSpaceOnUse"
                            >
                              <rect x={0} y={0} width={4} height={4} className="text-gray-100" fill="currentColor" />
                            </pattern>
                          </defs>
                          <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                        </svg>
                      </div>
                      <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                        <img
                          className="w-full rounded-md lg:h-full lg:w-auto lg:max-w-none"
                          src="https://res.cloudinary.com/minimum-viable/image/upload/v1620724819/virgintransformers/transformer_omfvmn.png"
                          alt="Electric Transformer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="about">
              <div className="bg-white">
                <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8 sm:py-24">
                  <div className="text-center">
                    <h2 className="pb-4 text-base font-medium tracking-wide text-red-600 uppercase">About virgin transformers</h2>
                    <p className="mt-1 text-4xl font-medium text-gray-600 sm:tracking-tight sm:text-5xl lg:text-6xl">
                      Value based client solutions.
          </p>
                    <p className="max-w-xl mx-auto mt-5 text-xl text-gray-500">
                      We leverage our partnerships with suppliers and clients to deliver the maximum in fair value exchange; ensuring high quality products and workmanship are our calling card.
          </p>
                  </div>
                </div>
              </div>
            </section>
            <section id="quickfacts">
              <div className="pt-12 bg-white sm:pt-16">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                  <div className="max-w-4xl mx-auto text-center">
                    <h2 className="text-3xl font-medium text-gray-900 sm:text-4xl">
                      Quick facts about us
          </h2>
                    <p className="mt-3 text-xl text-gray-500 sm:mt-4">
                      Our passion and experience in numbers
          </p>
                  </div>
                </div>
                <div className="pb-12 mt-10 bg-white sm:pb-16">
                  <div className="relative">
                    <div className="absolute inset-0 bg-white h-1/2" />
                    <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                      <div className="max-w-4xl mx-auto">
                        <dl className="bg-white rounded-lg shadow-lg sm:grid sm:grid-cols-3">
                          <div className="flex flex-col p-6 text-center border-b border-gray-100 sm:border-0 sm:border-r">
                            <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">Black Woman Owned</dt>
                            <dd className="order-1 text-5xl font-medium text-red-600">100%</dd>
                          </div>
                          <div className="flex flex-col p-6 text-center border-t border-b border-gray-100 sm:border-0 sm:border-l sm:border-r">
                            <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">Team Experience</dt>
                            <dd className="order-1 text-5xl font-medium text-red-600">25 yrs</dd>
                          </div>
                          <div className="flex flex-col p-6 text-center border-t border-gray-100 sm:border-0 sm:border-l">
                            <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">High-Impact Projects</dt>
                            <dd className="order-1 text-5xl font-medium text-red-600">35</dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="what-we-do">
              <div className="relative py-16 sm:py-24 lg:py-32 bg-truegrey-100">
                <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                  <h2 className="text-base font-medium tracking-wider text-red-600 uppercase">What We Do</h2>
                  <p className="mt-2 text-3xl font-medium tracking-tight text-gray-600 sm:text-4xl">
                    Transformer Services and Support
        </p>
                  <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
                    We pride ourselves in being highly experienced in the manufacture, sales, servicing and support of a wide range of transformer related products. Our clients look to us for expert advice when it comes to acquiring or maximising the performance and life expectancy of their transformers and related components
        </p>
                  <div className="mt-12">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                      {Whatwedo.map((Whatwedo) => (
                        <div key={Whatwedo.name} className="pt-6">
                          <a href={Whatwedo.href}><div className="flow-root px-6 pb-8 bg-white rounded-lg shadow-lg hover:shadow-2xl">
                            <div className="-mt-6">
                              <div>
                                <span className="inline-flex items-center justify-center p-3 bg-red-500 rounded-md shadow-lg">
                                  <Whatwedo.icon className="w-6 h-6 text-white" aria-hidden="true" />
                                </span>
                              </div>
                              <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{Whatwedo.name}</h3>
                              <p className="mt-5 text-base text-gray-500">
                                {Whatwedo.description}
                              </p>
                            </div>
                          </div></a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="CTA">
              <div className="relative bg-red-500">
                <div className="h-56 bg-red-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                  <img
                    className="object-cover w-full h-full"
                    src="https://res.cloudinary.com/minimum-viable/image/upload/c_scale,q_51,w_2000/v1621340767/virgintransformers/power-caution_knaluc.jpg"
                    alt=""
                  />
                </div>
                <div className="relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-16">
                  <div className="md:ml-auto md:w-1/2 md:pl-10">
                    <h2 className="text-base font-medium tracking-wider text-white uppercase">virgin transformers - more power to you</h2>
                    <p className="mt-2 text-3xl font-medium tracking-tight text-white sm:text-4xl">We’re here to help</p>
                    <p className="mt-3 text-lg text-white">
                      We would love to chat to you about your electrical components and transformer needs.
          </p>
                    <div className="mt-8">
                      <div className="inline-flex rounded-md shadow">
                        <Link
                          to="/contact"
                          className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50"
                        >
                          Get in touch
                <ExternalLinkIcon className="w-5 h-5 ml-3 -mr-1 text-gray-400" aria-hidden="true" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>


        </Layout>
      </Devtools>
    </div >
  );
}
