import React from 'react'
import { Link } from 'gatsby'
import {
    BeakerIcon,
    LightningBoltIcon,
    TrashIcon,
    RefreshIcon,
    ShieldCheckIcon,
    ChevronRightIcon,
    StarIcon,
    ExternalLinkIcon,
    AnnotationIcon,
    GlobeAltIcon,
    MailIcon,
    ScaleIcon
} from '@heroicons/react/outline'


const Whatwedo = [
    { name: 'Product Sales', icon: LightningBoltIcon, description: 'We stock a wide variety of coils, solenoids, drain valves, break coils and clutch coils for motors.', href: '#', },
    { name: 'Analysis & Diagnosis', icon: BeakerIcon, description: 'Our analysis and diagnosis service helps keep your electrical equipment in perfect working condition.', href: '#', },
    { name: 'Assessment & Testing', icon: ShieldCheckIcon, description: 'Whether at our facility or called out to the field, we are experts at breakdown root cause analysis and recommendations.', href: '#', },
    { name: 'Disposal Services', icon: TrashIcon, description: "We help our clients with environmentally conscious collection and disposal of your equipment's consumables and components.", href: '#', },
    { name: 'Repair & Refurbishment', icon: RefreshIcon, description: 'From leaks to major upgrades and refurbishment, we have a cost effective solution for you.', href: '#', },
]

export default Whatwedo



